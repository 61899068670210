<template>
  <div>
    <Search></Search>
    <div class="login">
      <el-card class="login_box">
        <div>
          <span
            class="login_title"
            :class="{ active: titleActive }"
            type="text"
            @click="goPhoneLogin"
            >账号登录</span
          >
          <span
            class="login_title"
            :class="{ active: !titleActive }"
            type="text"
            @click="goPhoneLogin"
            >手机登录</span
          >
        </div>
        <div v-show="titleActive">
          <!-- 账号登录表单 -->
          <el-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            v-show="isLogin"
            @keyup.enter.native="login"
          >
            <!-- 账号 -->
            <el-form-item prop="user_account">
              <el-input
                v-model="loginForm.user_account"
                prefix-icon="fa fa-user-o"
                placeholder="请输入账号"
              >
              </el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="user_password">
              <el-input
                v-model="loginForm.user_password"
                prefix-icon="fa fa-lock fa-lg"
                type="password"
                placeholder="请输入密码"
              >
              </el-input>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item>
              <el-button
                v-if="isLogin"
                class="login_btn"
                type="primary"
                @click="login"
                >登录</el-button
              >
              <el-button class="login_btn" type="warning" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
            <el-form-item>
              <span>没有账号，</span>
              <el-button type="text" @click="goLogin">前往注册</el-button>
            </el-form-item>
          </el-form>
          <!-- 注册表单 -->
          <el-form
            ref="registerFormRef"
            :model="registerForm"
            :rules="registerFormRules"
            v-show="!isLogin"
            @keyup.enter.native="register"
          >
            <!-- 账号 -->
            <el-form-item prop="user_account">
              <el-input
                v-model="registerForm.user_account"
                prefix-icon="fa fa-user-o"
                placeholder="请输入账号"
              >
              </el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="user_password">
              <el-input
                v-model="registerForm.user_password"
                prefix-icon="fa fa-lock fa-lg"
                type="password"
                placeholder="请输入密码"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="confim_password">
              <el-input
                v-model="registerForm.confim_password"
                prefix-icon="fa fa-lock fa-lg"
                type="password"
                placeholder="请输入确认密码"
              >
              </el-input>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item>
              <el-button class="login_btn" type="primary" @click="register"
                >注册</el-button
              >
              <el-button class="login_btn" type="warning" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
            <el-form-item>
              <span>已有账号，</span>
              <el-button type="text" @click="goLogin">前往登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="!titleActive">
          <!-- 手机登录表单 -->
          <el-form
            ref="phoneFormRef"
            :model="phoneForm"
            :rules="phoneFormRules"
            @keyup.enter.native="login"
          >
            <!-- 手机号 -->
            <el-form-item prop="phone">
              <el-input
                v-model="phoneForm.phone"
                prefix-icon="el-icon-mobile"
                placeholder="请输入手机号"
              >
              </el-input>
            </el-form-item>
            <!-- 验证码 -->
            <el-form-item prop="code">
              <el-input
                v-model="phoneForm.code"
                prefix-icon="el-icon-unlock"
                type="password"
                placeholder="请输入验证码"
              >
                <el-button
                  class="codeBtn"
                  type="text"
                  @click="getCode"
                  :disabled="getCodeBtnDisable"
                  slot="append"
                  >{{ btnText }}</el-button
                >
              </el-input>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item>
              <el-button class="login_btn" type="primary" @click="phoneLogin"
                >登录/注册</el-button
              >
              <el-button class="login_btn" type="warning" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  import { userLoginApi, userRegisterApi, getCodeApi, phoneLoginApi } from '@/api/api'
  export default {
     components: {
      Search
    },
    data() {
      var validatorPhone = function (rule, value, callback) {
        if (value === '') {
          callback(new Error('手机号不能为空'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      }
      return {
        registerForm: {
          user_account: '',
          user_password: '',
          confim_password: ''
        },
        loginForm: {
          user_account: '',
          user_password: ''
        },
        registerFormRules: {
          // 账号验证
          user_account: [{
            required: true,
            message: '请输入账号',
            trigger: 'blur'
          }],
          // 密码验证
          user_password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }],
          confim_password: [{
            required: true,
            message: '请输入确认密码',
            trigger: 'blur'
          }]
        },
        loginFormRules: {
          // 账号验证
          user_account: [{
            required: true,
            message: '请输入账号',
            trigger: 'blur'
          }],
          // 密码验证
          user_password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }]
        },
        phoneForm: {
          phone: '',
          code: ''
        },
        phoneFormRules: {
          phone: [{
            required: true,
            validator: validatorPhone,
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }]
        },
        scene: 'login',
        isLogin: true,
        titleActive: true,
        awaitTime: 60,
        getCodeBtnDisable: false,
        btnText: '获取验证码'
      }
    },
    methods: {
      // 手机验证码登录
      phoneLogin() {
        this.$refs.phoneFormRef.validate(async valid => {
          if (!valid) return;
          const { data: res } = await phoneLoginApi(this.phoneForm)
          if (res.status != "10000") return this.$message.error(res.message);
          this.$message.success(res.message);
          this.loginSuccess(res)
        })
      },
      // 获取验证码
      getCode() {
        this.$refs.phoneFormRef.validateField('phone', async valid => {
          console.log(valid)
          if (valid) return;
          const { data: res } = await getCodeApi(this.phoneForm.phone, this.scene)
          if (res.status != 10000) return this.$message.error(res.message)
          this.$message.success(res.message)
          this.getCodeBtnDisable = true
          this.btnText = `${this.awaitTime}秒后重发`
          let that = this
          let timer = setInterval(function () {
            if (that.awaitTime > 1) {
              that.awaitTime--
              that.btnText = `${that.awaitTime}秒后重发`
            } else {
              clearInterval(timer)
              that.btnText = '获取验证码'
              that.getCodeBtnDisable = false
              that.awaitTime = 60
            }
          }, 1000)
        })
      },
      //   账号/手机登录切换
      goPhoneLogin() {
        this.titleActive = !this.titleActive
        this.resetForm()
      },
      // 登录/注册
      goLogin() {
        this.isLogin = !this.isLogin
        this.resetForm()
      },
      // 注册表单提交
      register() {
        this.$refs.registerFormRef.validate(async valid => {
          if (!valid) return;
          const isEqual = this.registerForm.user_password === this.registerForm.confim_password
          if (!isEqual) return this.$message.error('两次密码不相同，请重新输入')
          const { data: res } = await userRegisterApi(this.registerForm)
          if (res.status != "10000") return this.$message.error(res.message);
          this.$message.success('注册成功，请登录~');
          this.goLogin()
        })
      },
      // 登录表单提交
      login() {
        localStorage.removeItem('sefpc_userName')
        // 登录预验证
        this.$refs.loginFormRef.validate(async valid => {
          if (!valid) return;
          const { data: res } = await userLoginApi(this.loginForm)
          if (res.status != "10000") return this.$message.error(res.message);
          this.$message.success(res.message);
          this.loginSuccess(res)
        })
      },
      loginSuccess(res) {
        this.$store.commit('setToken', res.result.token);
        window.sessionStorage['tempFlag'] = true
        // 将登录名使用vuex传递到Home页面
        let id = res.result.user_info.user_id
        let account = res.result.user_info.user_account
        let name = res.result.user_info.user_nickname
        this.$store.commit('setUserId', id);
        this.$store.commit('setUserAccount', account);
        if (name) this.$store.commit('setUserName', name);
        // 通过编程式导航跳转到后台主页，路由地址是 /home
        this.$router.replace('/')
      },
      // 点击重置按钮，重置登录表单
      resetForm() {
        this.$refs.phoneFormRef.resetFields()
        if (this.isLogin) {
          this.$refs.loginFormRef.resetFields()
        } else {
          this.$refs.registerFormRef.resetFields()
        }
      },
    }
  }
</script>

<style lang="less" scoped>
.login {
  position: relative;
  min-height: 500px;
}

.login_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  width: 400px;
  background-color: #fff;
  border-radius: 3px;

  .login_title {
    display: inline-block;
    margin: 0 5px;
    font-size: 20px;
    color: #303133;
    border-bottom: 2px solid #fff;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .active {
    color: #409eff;
    border-bottom: 2px solid #409eff;
  }

  .codeBtn {
    width: 100px;
  }

  .login_btn {
    width: 48%;
  }
}
</style>